import React from 'react';

const Home = () => {
  return (
    <div>
      <section className="hero">
        <video className="hero-video" autoPlay muted loop playsInline>
          <source src="/loop.mp4" type="video/mp4" />
           Your browser does not support the video tag.
        </video>
      </section>

      <section className="features">
        <h2>Features</h2>
        <div className="features-grid">
          <div className="feature-card">AI-Generated Memes</div>
          <div className="feature-card">Blockchain Integration</div>
          <div className="feature-card">Secure Rewards</div>
        </div>
      </section>

      <section className="tokens-container">
        <h2>System Workflow</h2>
        <div className="tokens-grid">
          <div className="token-card">
            <h3>Self-Deployment</h3>
            <p>The system deploys itself on the blockchain, initializing its Main Token</p>
            <p></p>
          </div>
          <div className="token-card">
            <h3>Neuric AI Agent</h3>
            <p>Trinity Ecosystem featuring Neuric AI Agent. An autonomous AI for token creation and deployment, driving blockchain innovation.</p>
            <p></p>
          </div>
          <div className="token-card">
            <h3>Revenue Distribution</h3>
            <p>Smart contracts autonomously calculate and distribute rewards to Main Token holders</p>
            <p></p>
          </div>
        </div>
      </section>

      <div className="footer-elements">
        <div className="footer-text">
          ca: 0xFC12b25Cc16Cf446E9794E5B868e11332f7e31A6
        </div>
        <div className="social-links">
          <a href="https://x.com/TrinityAIagent" target="_blank" rel="noopener noreferrer">
            <img src="/twitter.png" alt="Twitter" />
          </a>
          <a href="https://t.me/PortalTrinity" target="_blank" rel="noopener noreferrer">
            <img src="/telegram.png" alt="Telegram" />
          </a>
          <a href="https://personalautonomouss-organization.gitbook.io/trinity-whitepaper/5.-technical-architecture" target="_blank" rel="noopener noreferrer">
            <img src="/medium.png" alt="GitHub" />
          </a>
          <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x432fd44416a290864a62ccbc5630f6450c13ef61?t=1734278765795" target="_blank" rel="noopener noreferrer">
            <img src="/dextools.png" alt="Dextools" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
