import React, { useEffect, useState } from "react";

const Tokens = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true); // To manage the loading state
  const [error, setError] = useState(null); // To handle errors
  const [countdown, setCountdown] = useState(null); // Countdown state in seconds
  const [targetDate, setTargetDate] = useState(null); // Target date from the backend

  const fetchTokens = async () => {
    try {
      const response = await fetch("https://109.236.91.154/read_all?collection=tokens");
      if (!response.ok) {
        throw new Error("Failed to fetch tokens");
      }
      const data = await response.json();
      setTokens(data); // Update the tokens state
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Stop the loading state
    }
  };

 // const fetchCountdownDate = async () => {
 //   try {
   //   const response = await fetch("https://109.236.91.154/get_countdown"); // Fetch countdown date from backend
   //   if (!response.ok) {
   //     throw new Error("Failed to fetch countdown date");
   //   }

   //   const data = await response.json();
   //   if (data.countdown_date) {
   //     const targetDate = new Date(data.countdown_date); // Automatically parses timezone
   //     setTargetDate(targetDate);
   //     calculateCountdown(targetDate);
   //   } else {
   //     throw new Error("Invalid countdown date");
   //   }
  //  } catch (err) {
  //    setError(err.message);
  //  }
//  };

  const calculateCountdown = (targetDate) => {
    const interval = setInterval(() => {
      const now = new Date();
      const diffInSeconds = Math.max(Math.floor((targetDate - now) / 1000), 0);

      if (diffInSeconds <= 0) {
        clearInterval(interval);
        fetchTokens(); // Refresh tokens when the countdown ends
      }

      setCountdown(diffInSeconds);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  };

  useEffect(() => {
  //  fetchCountdownDate(); // Fetch the countdown date from the backend
    fetchTokens(); // Fetch tokens initially
  }, []);

  const formatCountdown = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  if (loading) {
    return <p>Loading tokens...</p>; // Show a loading message
  }

  if (error) {
    return <p>Error: {error}</p>; // Show error message if the fetch fails
  }

  return (
    <div className="tokens-page">
      <h2>Meme Tokens Deployed by TRINITY</h2>
      <h3>Time until new meme deploy: 16:00:00</h3> 

      <div className="tokens-grid">
        {tokens.length > 0 ? (
          tokens.map((token) => (
            <div key={token.id} className="token-card">
              <a
                href={`https://pump.fun/coin/${token.TokenDynMint}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>{token.TokenDynAddress}</h3>
              </a>
            </div>
          ))
        ) : (
          <p>No tokens found.</p>
        )}
      </div>
    </div>
  );
};

export default Tokens;
