import React, { useState, useEffect } from "react";

const Profile = () => {
  const [wallet, setWallet] = useState(null);
  const [tokensToClaim, setTokensToClaim] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // For error handling

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        setWallet(accounts[0]); // Imposta l'indirizzo Ethereum del wallet
      } catch (error) {

      }
    } else {
      alert("Ethereum Wallet not found! Install MetaMask to connect.");
    }
  };

  useEffect(() => {
    if (wallet) {
      // Fetch wallet data from the Flask backend
      const fetchWalletData = async () => {
        try {
          console.log(wallet);
          const response = await fetch(
            `https://109.236.91.154/read_field?collection=wallets&field=WalletAddress&value=${wallet}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch tokens");
          }
          const data = await response.json();
          console.log(data);
        // Transform the response into an array of tokens
          const transformedTokens = Object.entries(data).map(([tokenName, tokenAmount]) => ({
            name: tokenName,
            amount: tokenAmount,
          }));

          setTokens(transformedTokens);
          setTokensToClaim(transformedTokens);
        } catch (err) {

        } finally {
          setLoading(false);
        }
      };

      fetchWalletData();
    }
  }, [wallet]);

  const claimToken = async (tokenId) => {
    try {
      const tokenToClaim = tokens.find((t) => t.id === tokenId);

      if (!tokenToClaim) {
        alert("Token not found!");
        return;
      }

      const solanaWallet = prompt("Enter your Solana wallet address:");
      if (!solanaWallet) {
        alert("Solana wallet address is required!");
        return;
      }

      const payload = {
        wallet: wallet, // Indirizzo Ethereum
        solanaWallet: solanaWallet, // Indirizzo Solana
        amount: tokenToClaim.amount,
        name: tokenToClaim.name,
      };

      const response = await fetch("https://109.236.91.154/claim", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        alert(`Successfully claimed ${tokenToClaim.name}! Due to network congestion it may take up to 20 minutes`);
        setTokensToClaim(tokensToClaim.filter((token) => token.id !== tokenId));
        setTokens(tokensToClaim.filter((token) => token.id !== tokenId));
      } else {
        alert(`Error claiming ${tokenToClaim.name}: ${result.error}`);
      }
    } catch (error) {
      console.error("Error claiming token:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };
  if (loading) {
    return <p>Loading tokens...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  
  return (
    <div className="profile-page">
      <h2>Your Profile</h2>
      {!wallet ? (
        <button onClick={connectWallet} className="retro-button">
          Connect Wallet
        </button>
      ) : (
        <div>
          <h3>Claimable Tokens</h3>
          <div className="tokens-grid">
            {tokens.length > 0 ? (
              tokens.map((token, index) => (
                <div key={index} className="token-card">
                  <h3>{token.name}</h3>
                  <p>Amount: {token.amount}</p>
                  <button onClick={() => claimToken(token.id)} className="retro-button" >Claim </button>
                </div>
              ))
            ) : (
              <p>No tokens available to claim.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Profile;
